.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.style1 {
  background-image: url("../public/assets/img/why-us-bg.jpg");
}

.style2 {
  color: #f57813;
}
.style3 {
  color: #15a04a;
}
.style4 {
  color: #d90769;
}
.style5 {
  color: #15bfbc;
}
.style6 {
  color: #f5cf13;
}

.style7 {
  color: #1335f5;
}

.style8 {
  color: #ffbb2c;
}
.style9 {
  color: #5578ff;
}
.style10 {
  color: #e80368;
}
.style11 {
  color: #e361ff;
}
.style12 {
  color: #47aeff;
}

.style13 {
  color: #ffa76e;
}

.style14 {
  color: #11dbcf;
}
.style15 {
  color: #ff5828;
}

.style16 {
  min-height: 400px;
}

.about-bg {
  background-image: url("../public/assets/img/why-us-bg.jpg");
}

.about-header-bg {
  background-image: url("../src/images/bgz/traiining.jpg");
}

.publications-header-bg {
  background-image: url("../src/images/bgz/publications-bg.jpg");
}

.App-link {
  color: #61dafb;
}

.language-flag {
  width: 30px;
  height: 17px;
}
/*public/assets/img/services-header.jpg*/
.services-pg {
  background-image: url("../src/images/bgz/bgservice-hdr.png");
}

.contactus-hd-pg {
  background-image: url("../src/images/bgz/contacting.jpg");
}

.buttons {
  background-color: rgba(3, 101, 160);
  color: #fff;
  height: 15px;
  padding: 10px 15px;
  border-radius: 7px;
}

.viewmore-link {
  margin-left: 10px;
  color: rgba(3, 101, 160);
}

.service-img {
  width: 120px;
  height: 120px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
